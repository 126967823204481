/**
 * handleClose: func
 * show: boolean for show/hide
 * cls: string for custom classname
 * background: string for which X to use for contrast
 * **/
import React, {useState, useEffect} from "react";
import {Link} from 'gatsby-plugin-react-i18next';
import Modal from './modal';



const UpgraderModal = ({showLink}) => {

  const [showModal, setShowModal] = useState(false);

  function handleShowModal() {
    setShowModal(prevShowModal => !prevShowModal);
    document.body.classList.toggle('stop-body-scroll');
  }

  useEffect(() => {
    if(localStorage.getItem('UPGRADE_MODAL') === null) {
      handleShowModal();
      localStorage.setItem('UPGRADE_MODAL', 'seen');
    }
  }, []);

  return (
      <>
        {
          showLink ?
              <>
                <a className="btn btn--orange" onClick={handleShowModal} href={'javascript:void(0)'}>Learn about Digilock's Upgrader Program</a>
                <p style={{textAlign:'center', fontSize:'x-small'}}>
                  Save up to 50% on new locks
                  *Terms and Conditions applys</p>
              </>
              :
              null
        }
        <Modal show={showModal} handleClose={handleShowModal} cls={'curve'} background={'dark'}>
          <div className="modal-content">
            <div>
              <p className={'headline'}>
                Upgrade to new Digilock locks <br/>
                and save up to 50%*
              </p>
              <p>
                <i>Enjoy the latest lock advancements and designs at a <br/>
                  significant discount for Digilock customers.</i>
              </p>
              <a href="/support/upgrader-program/" className="btn btn--white">
                Learn More
              </a>
            </div>
          </div>
          <p className="terms">
          <Link to={'/support/upgrader-program/upgrader-terms-conditions/'}>* Terms and conditions apply</Link>
          </p>
        </Modal>
      </>

  );
};

export default UpgraderModal;