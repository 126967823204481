/**
 * handleClose: func
 * show: boolean for show/hide
 * cls: string for custom classname
 * background: string for which X to use for contrast
 * **/

import React from "react";
import xCloseImage from "../../images/modals/x.png";
import xCloseImageDark from "../../images/modals/x-dark.png";

const Modal = ({handleClose, show, cls, background, children}) => {

  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const onOverlayClick = e => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  }
  return (
      <div className={showHideClassName} onClick={onOverlayClick}>
        <section className={"modal-main " + cls}>
          {
            background === 'light' ?
                <img onClick={handleClose} className={'x-close'} src={xCloseImageDark} alt="close"/>
                :
                <img onClick={handleClose} className={'x-close'} src={xCloseImage} alt="close"/>
          }
          {children}
        </section>
      </div>
  );
};

export default Modal;